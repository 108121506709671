import { DEFAULT_HEADERS, HEADER_NAME } from '@/config/api.config';
import type { WithContext } from '@/types/api';
import type { GetInvoicesPayload, Invoice, InvoicePdf } from '@/types/invoice';
import { get, post } from '@/utils/requestWrapper';

const URL = {
    INVOICES: '/pos/invoices',
    INVOICE_DETAIL: '/shop/invoices/:id',
    INVOICE_PDF: '/shop/invoices/:id/pdf',
    INVOICE_EMAIL: '/pos/invoices/:id/email',
    RECEIPT_PDF: '/pos/receipts/:id/pdf',
    RECEIPT_TO_INVOICE: '/pos/receipts/:id/invoice',
};

function getInvoices(params: GetInvoicesPayload) {
    return get<WithContext<Invoice>>({
        url: URL.INVOICES,
        params,
        headers: {
            ...DEFAULT_HEADERS,
            [HEADER_NAME.ACCEPT]: 'application/ld+json',
        },
    });
}

function getInvoiceDetails({ id }: { id: string }) {
    return get<Invoice>({
        url: URL.INVOICE_DETAIL,
        pathParams: {
            id,
        },
    });
}

function getInvoicePdf({ id }: { id: string }) {
    return get<InvoicePdf, Blob>({
        url: URL.INVOICE_PDF,
        pathParams: {
            id,
        },
        responseType: 'blob',
        headers: {
            ...DEFAULT_HEADERS,
            [HEADER_NAME.ACCEPT]: 'application/pdf',
        },
        mapResponse(data, responseHeaders) {
            return {
                blob: data,
                height: responseHeaders
                    ? responseHeaders[HEADER_NAME.PDF_HEIGHT.toLowerCase()] as string
                    : undefined,
            };
        },
    });
}

function getReceiptPdf({ id }: { id: string }) {
    return get<InvoicePdf, Blob>({
        url: URL.RECEIPT_PDF,
        pathParams: {
            id,
        },
        responseType: 'blob',
        headers: {
            ...DEFAULT_HEADERS,
            [HEADER_NAME.ACCEPT]: 'application/pdf',
        },
        appendResponseHeaders: true,
        mapResponse(data, responseHeaders) {
            return {
                blob: data,
                height: responseHeaders
                    ? responseHeaders[HEADER_NAME.PDF_HEIGHT.toLowerCase()] as string
                    : undefined,
            };
        },
    });
}

function convertReceiptToInvoice({ receiptId, addressId }: { receiptId: string; addressId: number; }) {
    return post({
        url: URL.RECEIPT_TO_INVOICE,
        pathParams: {
            id: receiptId,
        },
        body: {
            addressId,
        },
    });
}

function sendInvoiceByEmail({ invoiceId, email }: { invoiceId: string; email: string; }) {
    return post({
        url: URL.INVOICE_EMAIL,
        pathParams: {
            id: invoiceId,
        },
        body: {
            email,
        },
    });
}

export default {
    getInvoices,
    getInvoiceDetails,
    getInvoicePdf,
    getReceiptPdf,
    convertReceiptToInvoice,
    sendInvoiceByEmail,
};
