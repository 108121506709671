import AdministrationService from '@/services/AdministrationService';
import OrderService from '@/services/OrderService';
import ProductService from '@/services/ProductService';
import { useAuthStore, useCustomerStore } from '@/stores';
import { AsyncStatus, type AsyncEntity } from '@/types/api';
import type { CartSummary } from '@/types/cart';
import {
    paymentMethodCodes,
    type PaymentMethod,
    type PaymentMethodCode,
    type Store,
    type Voucher,
} from '@/types/common';
import type { Customer, CustomerWithAddress } from '@/types/customer';
import {
    OrderAdjustmentType,
    type CashPaymentData,
    type Order,
    type OrderAdjustment,
    type OtherPaymentData,
    type Payment,
    type UpdateVoucherDetails,
    type VoucherPaymentData,
    type OfflinePaymentData,
    type UpdateItemQuantity,
    CheckoutState,
} from '@/types/order';
import type { ProductVariantsSearch } from '@/types/product';
import type { Wishlist } from '@/types/wishlist';
import { asyncFetchHandler, resetAsyncEntity } from '@/utils/asyncEntity';
import { defineStore, storeToRefs } from 'pinia';
import { computed, reactive, ref, watch, type ComputedRef } from 'vue';
import InvoiceService from '@/services/InvoiceService';
import { useRoute } from 'vue-router';
import { RouteNames } from '@/router/routeNames';

export const useNewSaleStore = defineStore('newSale', () => {
    const { addressDetail } = useCustomerStore();

    const updatingOrderItemId = ref();
    const latestWishlist = ref<Wishlist>();
    const customerNeedsChange = ref(false);
    const generateInvoice = ref(false);
    const route = useRoute();
    const orderItemUpdateQuantityQueue = ref<UpdateItemQuantity[]>([]);
    const orderItemsUpdateQuantityFromOrderData = ref<number[]>([]);

    const createOrder: AsyncEntity<Order> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    const order: AsyncEntity<Order> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    const cancelOrderEntity: AsyncEntity<Order> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    const addOrderItem: AsyncEntity<Order> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    const deleteOrderItem: AsyncEntity<null> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    const updateOrderItem: AsyncEntity<Order> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    const productSearch: AsyncEntity<ProductVariantsSearch[]> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
        abortController: new AbortController(),
    });

    const linkedCustomer = ref<{
        customer: Customer;
        address?: CustomerWithAddress;
    }>();

    const voucherDetails: AsyncEntity<Order> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    const orderAdjustments: AsyncEntity<OrderAdjustment[]> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    const paymentMethods: AsyncEntity<PaymentMethod[]> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    const voucherPayment: AsyncEntity<Voucher> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    const newPayment: AsyncEntity<Payment> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    const sendOrderInvoiceEmailEntity: AsyncEntity = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    const updatingOrder = computed(() => {
        return createOrder.status === AsyncStatus.Busy
            || order.status === AsyncStatus.Busy
            || addOrderItem.status === AsyncStatus.Busy
            || deleteOrderItem.status === AsyncStatus.Busy;
    });

    const someVoucherOrderItem = computed(() => order
        .data?.items.some((orderItem) => orderItem.voucher));

    const cartSummary: ComputedRef<CartSummary | null> = computed(() => {
        if (!order.data) {
            return null;
        }

        const posItemDiscountTotal = orderAdjustments.data
            ?.filter((a) => a.type === OrderAdjustmentType.POS_ITEM_DISCOUNT)
            .reduce((acc, a) => acc + a.amount, 0)
            || 0;

        // Get discounts from adjustments, filter by type and group them by type/label
        const discounts = [
            ...(orderAdjustments.data)
                ? [
                    ...orderAdjustments.data
                        .filter((a) => a.type === OrderAdjustmentType.ORDER_PROMOTION)
                        .reduce((acc, a) => {
                            const key = `${a.type}-${a.label}`;
                            const item = acc.get(key) || Object.assign({}, a, {
                                amount: 0,
                            });
                            item.amount += a.amount;
                            return acc.set(key, item);
                        }, new Map()).values(),
                ].map((adjustment) => ({
                    name: adjustment.label,
                    amount: adjustment.amount,
                }))
                : [],
        ];

        // subtotal of each item + pos_item_discounts
        const subtotal = order.data.items
            .reduce((prev, item) => prev + item.subtotal, 0) + posItemDiscountTotal;

        const payments = order.data.payments.reduce<Record<string, number>>((acc, payment) => {
            const paymentCode = typeof payment.method === 'string' ? payment.method.match(/([^/]+$)/) : null;
            if (paymentCode && paymentMethodCodes.includes(paymentCode[0] as PaymentMethodCode)) {
                if (paymentCode[0] in acc) {
                    acc[paymentCode[0]] += payment.amount;
                } else {
                    acc[paymentCode[0]] = payment.amount;
                }
            }
            return acc;
        }, {});

        return {
            total: order.data.total,
            subtotal,
            discounts,
            payments,
        };
    });

    const disableSubmit = computed(() => (linkedCustomer.value === undefined && someVoucherOrderItem.value));
    const allowedToCancelOrder = computed(() => order.data?.checkoutState !== CheckoutState.Completed);

    const totalToPay = computed(() => {
        if (!order.data) {
            return undefined;
        }

        const totalPaid = cartSummary.value?.payments
            ? Object.values(cartSummary.value?.payments).reduce((acc, val) => acc + val, 0)
            : 0;
        return order.data.total - totalPaid;
    });

    function createNewOrder() {
        return asyncFetchHandler({
            asyncEntity: createOrder,
            service: OrderService.createNewOrder,
        })({}).then(async (response) => {
            if (response instanceof Error) {
                return response;
            }

            order.data = response;
            await resetLinkedCustomer();

            return response;
        });
    }

    function getStoredOrder() {
        const tokenValue = order.data?.tokenValue || '';

        return asyncFetchHandler({
            asyncEntity: order,
            service: OrderService.getOrderDetail,
        })({ tokenValue });
    }

    function getProducts(query: string) {
        if (productSearch.status === AsyncStatus.Busy && productSearch?.abortController?.abort) {
            productSearch.abortController.abort();
        }

        productSearch.abortController = new AbortController();

        return asyncFetchHandler({
            asyncEntity: productSearch,
            service: ProductService.getProductVariantsSearch,
        })({
            query,
            page: 1,
            itemsPerPage: 20, // = max results to show when searching
            signal: productSearch.abortController.signal,
        });
    }

    function addItemToOrder({ productCode, quantity = 1 }: { productCode: string; quantity?: number }) {
        const tokenValue = order.data?.tokenValue || '';
        return asyncFetchHandler({
            asyncEntity: addOrderItem,
            service: OrderService.addItemToOrder,
        })({
            tokenValue,
            productVariant: productCode,
            quantity,
        }).then((response) => {
            if (response instanceof Error) {
                return;
            }

            order.data = response;
        });
    }

    function deleteItemFromOrder(orderItemId: number) {
        const tokenValue = order.data?.tokenValue || '';
        return asyncFetchHandler({
            asyncEntity: deleteOrderItem,
            service: OrderService.deleteItemFromOrder,
        })({
            tokenValue,
            orderItemId,
        });
    }

    function updateProductDiscount({ orderItemId, discount }: { orderItemId: number; discount: number }) {
        const tokenValue = order.data?.tokenValue || '';
        updatingOrderItemId.value = orderItemId;
        return asyncFetchHandler({
            asyncEntity: updateOrderItem,
            service: OrderService.updateItemDiscount,
        })({
            tokenValue,
            orderItemId,
            discount,
        }).then((response) => {
            if (response instanceof Error) {
                return;
            }

            order.data = response;
        });
    }

    function updateProductWishlist({ orderItemId, wishlist }: { orderItemId: number; wishlist: Wishlist }) {
        const tokenValue = order.data?.tokenValue || '';
        updatingOrderItemId.value = orderItemId;
        latestWishlist.value = wishlist;

        return asyncFetchHandler({
            asyncEntity: updateOrderItem,
            service: OrderService.updateItemWishlist,
        })({
            tokenValue,
            orderItemId,
            wishlistCode: wishlist.code,
        }).then((response) => {
            if (!(response instanceof Error)) {
                order.data = response;
            }

            return response;
        });
    }

    function updateOrderItemQuantity({ orderItemId, quantity }: UpdateItemQuantity) {
        const tokenValue = order.data?.tokenValue || '';
        updatingOrderItemId.value = orderItemId;

        if (updateOrderItem.status === AsyncStatus.Busy) {
            const existingUpdateIndex = orderItemUpdateQuantityQueue.value
                .findIndex((update) => update.orderItemId === orderItemId);

            if (existingUpdateIndex > -1) {
                orderItemUpdateQuantityQueue.value[existingUpdateIndex].quantity = quantity;
            } else {
                orderItemUpdateQuantityQueue.value.push({ orderItemId, quantity });
            }

            return Promise.resolve();
        }

        return asyncFetchHandler({
            asyncEntity: updateOrderItem,
            service: OrderService.updateItemQuantity,
        })({
            tokenValue,
            orderItemId,
            quantity,
        }).then((response) => {
            if (response instanceof Error) {
                orderItemsUpdateQuantityFromOrderData.value.push(orderItemId);

                return response;
            }

            order.data = response;
            return response;
        }).finally(() => {
            updatingOrderItemId.value = null;

            if (orderItemUpdateQuantityQueue.value.length > 0) {
                const nextUpdate = orderItemUpdateQuantityQueue.value.shift();
                if (nextUpdate) {
                    updateOrderItemQuantity(nextUpdate);
                }
            }
        });
    }

    function updateVoucherDetails({ orderItemId, voucherDetails: details }: {
        orderItemId: number;
        voucherDetails: UpdateVoucherDetails;
    }) {
        const tokenValue = order.data?.tokenValue || '';
        return asyncFetchHandler({
            asyncEntity: voucherDetails,
            service: OrderService.updateVoucherDetail,
        })({
            tokenValue,
            orderItemId,
            voucherDetails: details,
        }).then((response) => {
            if (response instanceof Error) {
                return;
            }

            order.data = response;
        });
    }

    function applyCouponCode(couponCode: string) {
        const tokenValue = order.data?.tokenValue || '';
        return asyncFetchHandler({
            asyncEntity: order,
            service: OrderService.updateOrder,
        })({
            tokenValue,
            couponCode,
        });
    }

    function getOrderAdjustments() {
        const tokenValue = order.data?.tokenValue || '';
        return asyncFetchHandler({
            asyncEntity: orderAdjustments,
            service: OrderService.getOrderAdjustments,
        })({
            tokenValue,
        });
    }

    async function setLinkedCustomer({ customer, address }: {
        customer: Customer;
        address?: CustomerWithAddress;
    }) {
        linkedCustomer.value = {
            customer,
            address,
        };

        if (address && address.vatNumber) {
            generateInvoice.value = true;
        }

        await addLinkedCustomerToOrder();
    }

    async function resetLinkedCustomer() {
        linkedCustomer.value = undefined;
        generateInvoice.value = false;
        await setPosUnitStoreAsCustomer();
    }

    function addLinkedCustomerToOrder() {
        if (linkedCustomer.value) {
            const { vatNumber, ...addressWithoutVatNumber } = linkedCustomer.value.address || {};
            return asyncFetchHandler({
                asyncEntity: order,
                service: OrderService.updateOrder,
            })({
                tokenValue: order.data?.tokenValue || '',
                billingAddress: !generateInvoice.value && vatNumber
                    ? addressWithoutVatNumber
                    : linkedCustomer.value.address,
                email: linkedCustomer.value.customer.email,
            });
        }

        return setPosUnitStoreAsCustomer();
    }

    function setPosUnitStoreAsCustomer() {
        const { posUnitDetail } = storeToRefs(useAuthStore());
        if (posUnitDetail.value?.data) {
            return updateOrderCustomer(posUnitDetail.value.data.store);
        } else if (order.data) {
            return useAuthStore().getPosUnit(order.data.posUnit.id).then((response) => {
                if (!(response instanceof Error) && response.store) {
                    return updateOrderCustomer(response.store);
                }

                return Promise.reject(new Error('No store found'));
            });
        }

        return Promise.reject(new Error('No order found'));

        function updateOrderCustomer(store: Store) {
            const { vatNumber, ...addressWithoutVatNumber } = store.address;
            return asyncFetchHandler({
                asyncEntity: order,
                service: OrderService.updateOrder,
            })({
                tokenValue: order.data?.tokenValue || '',
                email: store.email,
                billingAddress: addressWithoutVatNumber,
            });
        }
    }

    function resetProductSearch() {
        resetAsyncEntity(productSearch);
    }

    function getPaymentMethods() {
        return asyncFetchHandler({
            asyncEntity: paymentMethods,
            service: AdministrationService.getPaymentMethods,
        })({});
    }

    function getVoucherPayment(voucherCode: string) {
        return asyncFetchHandler({
            asyncEntity: voucherPayment,
            service: AdministrationService.getVoucher,
        })(voucherCode);
    }

    function createCashPayment(paymentData: CashPaymentData) {
        return asyncFetchHandler({
            asyncEntity: newPayment,
            service: OrderService.createPayment<CashPaymentData>,
        })({
            orderTokenValue: order.data?.tokenValue || '',
            paymentMethod: 'cash',
            paymentData,
        });
    }

    function createVoucherPayment(voucherCode: string) {
        return asyncFetchHandler({
            asyncEntity: newPayment,
            service: OrderService.createPayment<VoucherPaymentData>,
        })({
            orderTokenValue: order.data?.tokenValue || '',
            paymentMethod: 'voucher',
            paymentData: { voucherCode },
        });
    }

    function createOtherPayment({ amount, type }: { amount: number; type: string }) {
        return asyncFetchHandler({
            asyncEntity: newPayment,
            service: OrderService.createPayment<OtherPaymentData>,
        })({
            orderTokenValue: order.data?.tokenValue || '',
            paymentMethod: 'other',
            paymentData: { amount, type },
        });
    }

    function createOfflinePayment({ amount, type }: { amount: number; type: string }) {
        return asyncFetchHandler({
            asyncEntity: newPayment,
            service: OrderService.createPayment<OfflinePaymentData>,
        })({
            orderTokenValue: order.data?.tokenValue || '',
            paymentMethod: 'offline_terminal',
            paymentData: { amount, type },
        });
    }

    function sendOrderInvoiceEmail({ invoiceId, email }: { invoiceId: string; email: string }) {
        return asyncFetchHandler({
            asyncEntity: sendOrderInvoiceEmailEntity,
            service: InvoiceService.sendInvoiceByEmail,
        })({ invoiceId, email });
    }

    async function startNewSale() {
        resetAsyncEntity(order);
        resetAsyncEntity(orderAdjustments);

        await createNewOrder();

        linkedCustomer.value = undefined;
        latestWishlist.value = undefined;

        return Promise.resolve();
    }

    function cancelOrder() {
        return asyncFetchHandler({
            asyncEntity: cancelOrderEntity,
            service: OrderService.cancelOrder,
        })({ tokenValue: order.data?.tokenValue || '' });
    }

    watch(() => addressDetail.data, (address) => {
        if (linkedCustomer.value?.address && address && linkedCustomer.value.address.id === address.id) {
            linkedCustomer.value.address = address;
        }
    });

    watch(() => cancelOrderEntity.status, () => {
        if (cancelOrderEntity.status === AsyncStatus.Success) {
            startNewSale();
        }
    });

    watch(() => order.data, () => {
        const isOnNewSaleRoute = route?.matched.some((r) => r.name === RouteNames.New_Sale);
        if (order.data?.tokenValue && isOnNewSaleRoute) {
            getOrderAdjustments();
        }
    });

    watch(() => order.data?.items, (newItems, oldItems) => {
        newItems?.forEach((item) => {
            const oldItem = oldItems?.find((i) => item.id === i.id);
            if (oldItem && item.quantity !== oldItem.quantity) {
                orderItemsUpdateQuantityFromOrderData.value.push(item.id);
            }
        });
    });

    return {
        order, createOrder, createNewOrder, getStoredOrder, updatingOrder, updatingOrderItemId, updateOrderItem,
        linkedCustomer, setLinkedCustomer, resetLinkedCustomer, generateInvoice,
        productSearch, getProducts, resetProductSearch,
        addOrderItem, addItemToOrder, updateOrderItemQuantity, deleteItemFromOrder, deleteOrderItem,
        updateProductDiscount, updateProductWishlist,
        voucherDetails, updateVoucherDetails, applyCouponCode,
        someVoucherOrderItem, disableSubmit,
        paymentMethods, getPaymentMethods,
        voucherPayment, getVoucherPayment,
        latestWishlist,
        newPayment, createCashPayment, createVoucherPayment, createOtherPayment, createOfflinePayment,
        totalToPay, customerNeedsChange, cartSummary, orderAdjustments, getOrderAdjustments,
        startNewSale, cancelOrderEntity, cancelOrder, allowedToCancelOrder,
        sendOrderInvoiceEmailEntity, sendOrderInvoiceEmail,
        orderItemsUpdateQuantityFromOrderData,
    };
}, {
    persist: {
        paths: ['order', 'linkedCustomer'],
    },
});
