import { defineStore } from 'pinia';
import type { VueComponentType } from '@/types/common';
import ConfirmationModal, { type ConfirmationModalProps } from '@/components/ConfirmationModal.vue';
import { computed, reactive, ref, type ShallowRef, shallowRef } from 'vue';
import type { BaseModalProps } from '@/components/base/BaseModal.vue';

type ModalProps<T> = T & BaseModalProps;

interface ModalState<T = object> {
    component: ShallowRef<VueComponentType> | null;
    props: ModalProps<T> | null;
}

interface SlideOutPanelState {
    isOpen: boolean;
    onCloseIntent?: () => void;
    onAfterOpen?: () => void;
    onAfterClose?: () => void;
}

export const useUIStore = defineStore('UI', () => {
    const modalState: ModalState = reactive({
        component: null,
        props: null,
    });

    const slideOutPanelState: SlideOutPanelState = reactive({
        isOpen: false,
    });

    const modalProps = computed(() => modalState.props);
    const isMenuDisabled = ref(false);

    function openModal<T>(payload: ModalState<T>) {
        const { props, component } = payload;

        const body = document.body;

        if (body) {
            body.style.overflow = 'hidden';
        }

        modalState.component = component;
        modalState.props = props || {};
    }

    function closeModal() {
        modalState.component = null;

        const body = document.body;

        if (body) {
            body.style.overflow = 'auto';
        }

        if (typeof modalState.props?.afterClose === 'function') {
            modalState.props.afterClose();
        }

        modalState.props = {};
    }

    function adjustModalProps<T>(props: ModalProps<T>) {
        modalState.props = {
            ...modalState.props,
            ...props,
        };
    }

    function openConfirmationModal(props: ModalProps<ConfirmationModalProps>) {
        openModal<ConfirmationModalProps>({
            component: shallowRef(ConfirmationModal),
            props,
        });
    }

    function openSlideOutPanel() {
        slideOutPanelState.isOpen = true;
    }

    function closeSlideOutPanel() {
        slideOutPanelState.isOpen = false;
    }

    function onCloseIntentSlideOutPanel() {
        if (typeof slideOutPanelState.onCloseIntent === 'function') {
            slideOutPanelState.onCloseIntent();
        }
    }

    function onAfterCloseSlideOutPanel() {
        if (typeof slideOutPanelState.onAfterClose === 'function') {
            slideOutPanelState.onAfterClose();
            slideOutPanelState.onAfterClose = undefined;
        }
    }

    function onAfterOpenSlideOutPanel() {
        if (typeof slideOutPanelState.onAfterOpen === 'function') {
            slideOutPanelState.onAfterOpen();
            slideOutPanelState.onAfterOpen = undefined;
        }
    }

    function enableMenu() {
        isMenuDisabled.value = false;
    }

    function disableMenu() {
        isMenuDisabled.value = true;
    }

    return {
        modalState, modalProps,
        slideOutPanelState,
        openModal, closeModal, openConfirmationModal, adjustModalProps,
        openSlideOutPanel, closeSlideOutPanel, onAfterCloseSlideOutPanel, onAfterOpenSlideOutPanel,
        onCloseIntentSlideOutPanel,
        isMenuDisabled, enableMenu, disableMenu,
    };
});
