import { computed, type ComputedRef, reactive, ref, shallowRef } from 'vue';
import { defineStore } from 'pinia';
import { type AsyncEntity, AsyncStatus } from '@/types/api';
import { asyncFetchHandler } from '@/utils/asyncEntity';
import AdministrationService from '@/services/AdministrationService';
import type { Address, Country, PosUnit, Store } from '@/types/common';
import { useUIStore } from '@/stores/ui.store';
import SelectPreferredPrinterModal from '@/views/AppShell/SelectPreferredPrinterModal.vue';

export const useConstantsStore = defineStore('constants', () => {
    const preferredPrinterId = ref<string>();

    const countries: AsyncEntity<Country[]> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    const posUnits: AsyncEntity<PosUnit[]> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    const stores: ComputedRef<Store[]> = computed(() => {
        if (!posUnits.data) {
            return [];
        }

        return posUnits.data.reduce<Store[]>((acc, posUnit) => {
            if (posUnit.store) {
                acc.push(posUnit.store);
            }
            return acc;
        }, []);
    });

    function getCountries({ forceRefresh }: { forceRefresh?: boolean } = {}) {
        if (!forceRefresh && countries.data !== null) {
            return Promise.resolve(countries.data);
        }

        return asyncFetchHandler({
            asyncEntity: countries,
            service: AdministrationService.getCountries,
        })({
            itemsPerPage: 300, // fetch them all
        });
    }

    function getPosUnits({ forceRefresh }: { forceRefresh?: boolean } = {}) {
        if (!forceRefresh && posUnits.data !== null) {
            return Promise.resolve(posUnits.data);
        }

        return asyncFetchHandler({
            asyncEntity: posUnits,
            service: AdministrationService.getPosUnits,
        })({});
    }

    function isPosUnitStoreAddress(address: Address) {
        return posUnits.data?.some((posUnit) => (
            posUnit.store
                && posUnit.store.address.number === address.number
                && posUnit.store.address.street === address.street
                && posUnit.store.address.bus === address.bus
                && posUnit.store.address.city === address.city
                && posUnit.store.address.postcode === address.postcode
                && posUnit.store.address.countryCode === address.countryCode
                && posUnit.store.address.company === address.company
        ));
    }

    function showPrinterSelectDialog({ onPrinterSelected } : { onPrinterSelected?: (printerId: string) => void }) {
        const uiStore = useUIStore();
        uiStore.openModal({
            component: shallowRef(SelectPreferredPrinterModal),
            props: {
                fullscreen: true,
                width: 'narrow',
                onPrinterSelected,
                disableManuallyClose: true,
            },
        });
    }

    return {
        preferredPrinterId,
        countries, getCountries,
        posUnits, getPosUnits,
        stores,
        showPrinterSelectDialog,
        isPosUnitStoreAddress,
    };
}, {
    persist: true,
});
